import { useCallback } from 'react';

import { fetcher } from 'src/utils/fetcher';

const REGISTER_INTERACTION = '/shards/vacancy/register_interaction';

declare global {
    interface FetcherPostApi {
        [REGISTER_INTERACTION]: {
            body: {
                vacancyId: number;
            };
            queryParams: void;
            response: void;
        };
    }
}

const useRegisterInteraction = (vacancyId: number): (() => Promise<void>) => {
    return useCallback(async () => {
        try {
            await fetcher.post(REGISTER_INTERACTION, { vacancyId });
        } catch (error) {
            console.error(error);
        }
    }, [vacancyId]);
};

export default useRegisterInteraction;
